import EventListFilters from '../partials/event_list_filters'
import NonBlockedOccupationLogic from '../partials/non_blocked_occupation_logic'

$(document).on('turbolinks:load', () => {
  window.onpopstate = function (event) {
    if (event && event.state) {
      window.location.reload()
    }
  }

  if ($('#events-index').length !== 0) {

    // Setup clearable search
    $('#name_filter .input--clean').get(0).onclick = () => {
      $("#name_filter input[name='term']").val('')
    }

    // Setup selectors
    $('[select2-target]').select2({
      width: '100%'
    })
  }

  // Define advanced search toggle
  $('#advanced-search-dropdown').on('click', function () {
    $(this).toggleClass('opened')
    if ($(this).hasClass('opened')) {
      $('#advanced-search-filters-group').addClass('filters_events__opened')
    } else {
      $('#advanced-search-filters-group').removeClass('filters_events__opened')
    }
  })

  $(document).on('events-list-loaded', () => {
    new EventListFilters().setup()
    new NonBlockedOccupationLogic().setup()

    // Setup selectors
    $('[select2-target]').select2({
      width: '100%'
    })

    // If filters exist set to url
    if ($('[name="url_filters"]').val()) {
      // Url path host + subpages
      const urlPath = window.location.origin + window.location.pathname + '?' + $('[name="url_filters"]').val()
      // Push to history
      const url = new URL(urlPath)
      window.history.pushState({}, '', url)
    }
  })
})
